






































































































































































@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,700,800&display=swap');

#app {
  @apply font-sans text-base text-black;
}

.fade {
  &-enter {
    opacity: 0;
  }
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }
}

.pop-enter-active {
  animation: pop-in 0.33s ease;
}
.pop-leave-active {
  animation: pop-in 0.33s ease reverse;
}
@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
