











































































.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/img/portlandgov-growth-scenario-1920.jpg');
}

h1 {
  @apply text-3xl mb-6;
}

h2 {
  @apply text-2xl mb-5;
}

h3 {
  @apply text-xl mb-4;
}

ul {
  @apply list-disc list-outside pl-4;
}

p {
  @apply mb-3;
}
